import axios from 'axios'
import { APIINV as API } from '@/API.js'

const apiClient = axios.create({
  baseURL: `${API}/articulos/movimientos`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('argusblack.token')
  }
})

// Actualización del token de autorización
document.addEventListener('actualizar:token', function(e) {
  apiClient.defaults.headers['Authorization'] = e.detail
})

// Eliminación del token de autorización
document.addEventListener('eliminar:token', function() {
  apiClient.defaults.headers['Authorization'] = null
})

export default {
  ultimasEntradasJSON(params) {
    params = params || {}
    return apiClient.get(`ultimas-entradas.json`, { params: params })
  },

  ultimasSalidasJSON(params) {
    params = params || {}
    return apiClient.get(`ultimas-salidas.json`, { params: params })
  },

  movimientosJSON(params) {
    params = params || {}
    return apiClient.get(`${API}/articulos/movimientos.json`, { params: params })
  },

  movimientoJSON(idMovimiento) {
    return apiClient.get(`${idMovimiento}.json`)
  }
}